import logo from './logo.svg';
import './App.css';
import Home from './screens/Home';

function App() {
  return (
    <Home/>
  );
}

const HolaMundo = () => {
  return (
        <p>
          Hola mundo!
        </p>
  );
}

export default App;
