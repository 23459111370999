import React from "react";
import logo from '../logo.svg';

export default function Home(){
  return (
    <div className="bg-white flex flex-row justify-center w-full">
      <div className="bg-white w-[1280px] h-[2496px] relative">
        <div className="absolute w-[650px] h-[527px] top-[171px] left-[533px] rounded-[75px] border-[3px] border-solid border-[#2e2e2e] [background:linear-gradient(180deg,rgb(255,143.46,62.69)_0%,rgb(168.3,255,127.5)_25.5%,rgb(127.5,255,201.45)_51.5%,rgb(127.5,147.9,255)_78%,rgb(255,127.5,226.95)_100%)]" />
        
        <img
          className="absolute w-[62px] h-[46px] top-[38px] left-[609px] object-cover"
          alt="Artboard"
          src={logo}
        />
        <p className="absolute w-[350px] h-[314px] top-[216px] left-[96px] [font-family:'Jua-Regular',Helvetica] font-normal text-[#2e2e2e] text-[65px] tracking-[0] leading-[normal]">
          The way to share and organize all your links
        </p>
        <p className="absolute w-[350px] h-[86px] top-[578px] left-[96px] [font-family:'Helvetica_Neue-Bold',Helvetica] font-bold text-[#2e2e2e] text-[25px] tracking-[0] leading-[normal]">
          Join now to Listt and start sharing, it’s free!
        </p>
        <div className="absolute w-[1087px] h-[431px] top-[787px] left-[96px] bg-[#a8ff7f] rounded-[75px] border-[3px] border-solid border-[#2e2e2e]">
          <p className="absolute w-[350px] h-[86px] top-[252px] left-[656px] [font-family:'Helvetica_Neue-Italic',Helvetica] font-normal italic text-[#2e2e2e] text-[25px] tracking-[0] leading-[normal]">
            Morbi sapien neque, lobortis sed posuere at, molestie sed.
          </p>
          <p className="absolute w-[350px] h-[200px] top-[52px] left-[656px] [font-family:'Helvetica_Neue-Bold',Helvetica] font-bold text-[#2e2e2e] text-[36px] tracking-[0] leading-[normal]">
            Lorem ipsum dolor sit, consectetur adipiscing elit rhoncus.
          </p>
        </div>
        <div className="absolute w-[1087px] h-[431px] top-[1827px] left-[96px] bg-[#7fffc9] rounded-[75px] border-[3px] border-solid border-[#2e2e2e]">
          <p className="absolute w-[350px] h-[86px] top-[252px] left-[656px] [font-family:'Helvetica_Neue-Italic',Helvetica] font-normal italic text-[#2e2e2e] text-[25px] tracking-[0] leading-[normal]">
            Aliquam vitae erat vitae lectus vulputate.
          </p>
          <p className="absolute w-[350px] h-[200px] top-[52px] left-[656px] [font-family:'Helvetica_Neue-Bold',Helvetica] font-bold text-[#2e2e2e] text-[36px] tracking-[0] leading-[normal]">
            Nunc vitae dolor id orci fringilla ante ipsum primis orci vehicula.
          </p>
        </div>
        <div className="absolute w-[1087px] h-[431px] top-[1307px] left-[96px] bg-[#ff8f3e] rounded-[75px] border-[3px] border-solid border-[#2e2e2e]">
          <p className="absolute w-[350px] h-[86px] top-[252px] left-[76px] [font-family:'Helvetica_Neue-Italic',Helvetica] font-normal italic text-[#2e2e2e] text-[25px] tracking-[0] leading-[normal]">
            Customize all of your listt to match your essence.
          </p>
          <p className="absolute w-[350px] h-[200px] top-[52px] left-[76px] [font-family:'Helvetica_Neue-Bold',Helvetica] font-bold text-[#2e2e2e] text-[36px] tracking-[0] leading-[normal]">
            We know that it&#39;s more than just links. It’s you, your image, your style.
          </p>
        </div>
        <div className="absolute w-[1280px] h-[149px] top-[2347px] left-0 bg-[#2e2e2e]">
          <div className="absolute h-[18px] top-[124px] left-[562px] [font-family:'Helvetica_Neue-Regular',Helvetica] font-normal text-white text-[15px] text-center tracking-[0] leading-[normal] whitespace-nowrap">
            Made in Monterrey, MX
          </div>
        </div>
      </div>
    </div>
  );
};